<template>
    <div class="m-auto" style="max-width: 960px;">
        <el-card header="基本信息" shadow="never" class="m-3">
            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">状态</span>
                    <span class="property-value">{{order.statusName}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">供应商</span>
                    <span class="property-value">{{order.supplierName}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">买家</span>
                    <span class="property-value">{{order.buyerName}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">单据编号</span>
                    <span class="property-value">{{order.billNumber}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">支付金额</span>
                    <span class="property-value">{{order.paymentAmount}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">支付方式</span>
                    <span class="property-value">{{order.paymentMethodName}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">支付时间</span>
                    <span class="property-value">{{$moment.sdt(order.paymentTime)}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">备注</span>
                    <span class="property-value">{{order.remark}}</span>
                </el-col>
            </el-row>
        </el-card>

        <el-card header="订单项" shadow="never" class="m-3">
            <el-table :data="order.orderItems">
                <el-table-column prop="productSnapshot.name" label="商品" />
                <el-table-column prop="number" label="数量" width="80" />
                <el-table-column prop="unitPrice" label="单价" width="80" />
                <el-table-column prop="totalPrice" label="总价" width="100" />
                <el-table-column prop="refundAmount" label="退款金额" width="100" />
                <el-table-column prop="refundGatewayName" label="退款方式" width="100" />
                <el-table-column prop="refundTime" label="退款时间" width="150" :formatter="$moment.sdFormatter" />
            </el-table>
        </el-card>

        <el-card header="收获信息" shadow="never" class="m-3">
            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">收货地区</span>
                    <span class="property-value">{{order.deliveryRegion}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">收货详细地址</span>
                    <span class="property-value">{{order.deliveryAddress}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">收货人姓名</span>
                    <span class="property-value">{{order.consigneeName}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">收货人电话</span>
                    <span class="property-value">{{order.consigneePhone}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">快递公司</span>
                    <span class="property-value">{{order.expressCompany}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">快递单号</span>
                    <span class="property-value">{{order.expressNumber}}</span>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                id: this.$route.query.id,
                order: {},
            };
        },
        methods: {
            async loadOrder() {
                let response = await this.$axios.get('/api/Order/GetOrder', { params: { id: this.id } });
                this.order = response.data;
                document.title = this.order.billNumber;
            },
        },
        created() {
            document.title = this.$store.state.applicationName;

            this.loadOrder();
        },
    };
</script>

<style>
    .property-group {
        line-height: 40px;
    }

    .property-name {
        display: inline-block;
        color: gray;
    }

    .property-name::after {
        content: '：';
    }

    .property-value {
        display: inline-block;
    }
</style>